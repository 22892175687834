const admin = [{
  path: '/tenants',
  component: () => import(/* webpackChunkName: "tenants" */ '@/views/Index.vue'),
  children: [{
    path: '/',
    name: 'Tenants.List',
    component: () => import(/* webpackChunkName: "tenants" */ '@/views/admin/tenants/List.vue')
  }, {
    path: ':id',
    name: 'Tenants.Actions',
    component: () => import(/* webpackChunkName: "tenants" */ '@/views/admin/tenants/Actions.vue')
  }]
}, {
  path: '/user-queries',
  component: () => import(/* webpackChunkName: "user-queries" */ '@/views/admin/others/UserQueries.vue')
}, {
  path: '/email-subscriptions',
  component: () => import(/* webpackChunkName: "email-subscriptions" */ '@/views/admin/others/EmailSubscriptions.vue')
}]

export default admin