<template>
  <v-app>
    <nav-bar v-if="!$route.meta.isAuthPage"></nav-bar>
    <v-main>
      <layout></layout>
      <router-view :key="$route.fullPath"></router-view>
    </v-main>
  </v-app>
</template>
<script>
import { mapState } from 'vuex'
export default {
  components: {
    'nav-bar': () => import('@/components/Navbar.vue'),
    layout: () => import('@/components/Layout.vue')
  },
  created () {
    this.$store.commit('login')
    // shortcuts handler
    document.onkeydown = (e) => {
      e = e || window.Event
      const code = e.which || e.keyCode // Get key code
      if (![27].includes(code) && (!e.altKey)) return
      if (window.document.URL.includes('/login')) return
      e.preventDefault()
      e.stopPropagation()
      switch (code) {
        case 76: // { alt + l }
          if (!window.document.URL.includes('/login')) {
            this.$store.dispatch('logout')
          }
          break
        case 68: // { alt + d }
          this.$vuetify.theme.dark = !this.$vuetify.theme.dark
          break
      }
    }
  },
  computed: {
    ...mapState(['token'])
  },
  mounted () {
    if (!this.token) {
      this.$store.dispatch('logout')
      this.$router.push('/')
    }
  }
}
</script>
<style>
  html {
    -ms-overflow-style: none;
    scrollbar-width: none;
    user-select: none !important;
  }
  html::-webkit-scrollbar {
    display: none;
  }
  .v-main__wrap {
    background-color: #f8f9fb !important;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .v-list-item__action {
    margin-right: 15px !important;
  }
  .v-list-item {
    min-height: 42px !important;
  }
  .v-list-item__icon {
    margin: 9px 17px 0 0 !important;
  }
  .v-text-field__details {
    padding: 0 5px !important;
  }
  .v-messages__message {
    font-size: 11px !important;
  }
  .v-btn {
    text-transform: capitalize !important;
  }
  .user-select-none {
    -webkit-user-select: none !important; /* Safari */
    -ms-user-select: none !important; /* IE 10 and IE 11 */
    user-select: none !important; /* Standard syntax */
  }
  .dialog-header {
    font-size: 18px !important;
  }
</style>
