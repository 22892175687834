import cookie from 'vue-cookie'
// import { api } from '../plugins/axios'


export default {
  login (state) {
    let token = cookie.get(process.env.VUE_APP_PORTAL_YG_TOKEN)
    if (token) state.token = token

    let userDetails = cookie.get(process.env.VUE_APP_PORTAL_YG_USER)
    userDetails = (userDetails && userDetails !== 'undefined') ? JSON.parse(userDetails) : null
    if (userDetails) state.userDetails = userDetails

    let rememberme = cookie.get(process.env.VUE_APP_PORTAL_YG_REMEMBER_ME)
    rememberme = (rememberme && rememberme !== 'undefined') ? JSON.parse(rememberme) : null
    if (rememberme) state.rememberme = rememberme
  }
}