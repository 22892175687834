import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from '@/plugins/vuetify'
import common from '@/mixins/common'
import moment from '@/plugins/moment'
import VueCookie from '@/plugins/cookie'
import socket from '@/plugins/socketIO/socket'

// mixins
const mixins = { common }
Object.keys(mixins).forEach(key => {
  Vue.mixin(mixins[key])
})

// plugin registration
let plugins = { VueCookie, moment, socket }
Object.keys(plugins).forEach((key) => {
  Vue.use(plugins[key])
})

// event-bus
const eventBus = new Vue()
Vue.prototype.$eventBus = eventBus
export default eventBus


Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
