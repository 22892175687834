import { mapState } from 'vuex'
import { api } from '../plugins/axios'
export default {
  computed: {
    ...mapState(['userDetails']),
    xsOnly () {
      return this.$vuetify.breakpoint.xsOnly
    },
    imgbaseurl () {
      return process.env.VUE_APP_IMAGE_BASE_URL
    },
    // validations
    $_requiredValidation () {
      return [
        (val) => !!val && !/^\s*$/.test(val) || 'Required!'
      ]
    }
  },
  methods: {
    $_execute (method, url, payload, config) {
      return api[method](url, payload, config)
    },
    $_handleApiError (err) {
      // this.$root.$emit('snackbar', { color: 'error', text: (err?.response.data?.err?.message || err?.response.data || err) })
      this.$root.$emit('snackbar', { color: 'error', text: err })
    },
    $_copy (data) {
      return data ? JSON.parse(JSON.stringify(data)) : null
    },
    // date functions
    $_formatDate (date, fromFormat, toFormat) {
      if (!date) return null
      return this.$moment(date, (fromFormat || 'YYYY-MM-DDTHH:mm:ss'), true).isValid()
        ? this.$moment(date, (fromFormat || 'YYYY-MM-DDTHH:mm:ss')).format(toFormat || 'DD.MM.YYYY HH:mm')
        : null
    },
    $_isSameDate (start, end) {
      const from = this.$_formatDate(start, 'YYYY.MM.DDTHH:mm', 'YYYY.MM.DD')
      const to = this.$_formatDate(end, 'YYYY.MM.DDTHH:mm', 'YYYY.MM.DD')
      return start && end ? this.$moment(this.$moment(from, 'YYYY.MM.DD')).isSame(this.$moment(to, 'YYYY.MM.DD')) : null
    },
    $_utcToLocal (date, fromFormat = 'YYYY-MM-DDTHH:mm', toFormat) {
      return date
        ? this.$moment(this.$moment.utc(date, fromFormat).toDate()).format(toFormat || fromFormat)
        : null
    },
    $_localToUtc (date, fromFormat = 'YYYY-MM-DDTHH:mm', toFormat) {
      return date ? this.$moment.utc(this.$moment(date, fromFormat)).format(toFormat || fromFormat) : null
    }
  }
}