import cookie from 'vue-cookie'
import socket from '@/plugins/socketIO/config'
import router from '@/router'


export default {
  login ({ commit }, { user, token, rememberMe }) {
    socket.emit('login', user)
    let date = new Date()
    date.setTime(date.getTime() + (1*60*60*1000))
    let expires = date.toGMTString()
    cookie.set(process.env.VUE_APP_PORTAL_YG_TOKEN, token, { expires, SameSite: 'Strict' })
    cookie.set(process.env.VUE_APP_PORTAL_YG_USER, JSON.stringify(user), { SameSite: 'Strict' })
    cookie.set(process.env.VUE_APP_PORTAL_YG_REMEMBER_ME, JSON.stringify(rememberMe), { SameSite: 'Strict' })
    // const timezone = (appconfig && appconfig.timezone) ? appconfig.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone
    // localStorage.setItem('x-timezone', timezone)
    // api.defaults.headers['x-timezone'] = timezone
    commit('login')
  },
  logout ({ state }) {
    if (state && state.userDetails) socket.emit('logout', state.userDetails)
    cookie.delete(process.env.VUE_APP_PORTAL_YG_TOKEN)
    if (!state.rememberme) {
      cookie.delete(process.env.VUE_APP_PORTAL_YG_REMEMBER_ME)
      cookie.delete(process.env.VUE_APP_PORTAL_YG_USER)
    }
    router.push('/login')
  }
}