import Vue from 'vue'
import VueRouter from 'vue-router'
import admin from './admin'
import tenant from './tenant'
import user from './user'
import cookie from 'vue-cookie'

Vue.use(VueRouter)

// handling for same route navigation warning/ error
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [{
    path: '*',
    redirect: '/'
  }, {
    path: '/',
    name: 'Login',
    meta: { isAuthPage: true },
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue')
  }, {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue')
  },
  ...admin,
  ...tenant,
  ...user
  ]
})

router.beforeEach((to, from, next) => {
  let userDetails = cookie.get(process.env.VUE_APP_PORTAL_YG_USER)
  userDetails = (userDetails && userDetails !== 'undefined') ? JSON.parse(userDetails) : null
  if (userDetails && userDetails.role === 2) {
    if (to.name.includes('Bookings') || to.fullPath === '/') next()
    else next('/bookings')
  } else next()
})

export default router
